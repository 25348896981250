.containermain {
  width: 80%;
  margin: 0px auto;
  padding-top: 100px;
}
.sectionmain {
  margin: 50px auto 20px;
}
.sectionmain1 {
  margin: 30px auto;
}
.Heading {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  margin: 10px auto;

  color: #000000;
}
.HeadingRoman {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin: 10px auto -20px;

  color: #000000;
}
.HeadingRoman1 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;

  color: #000000;

  margin: 30px auto -20px;
}

.SubHeading {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;

  color: #000000;
}
.SubHeadingPoints {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  margin: 20px auto;

  color: #000000;
}
.SubHeading span {
  display: block;
}

.subPoints{
  text-align: justify;
  text-justify: inter-word;
  line-height: normal;
  text-wrap: balance;
}